export const clientNameFooter = "ALINE WETIUK";
export const titleMain = "Seja bem-vindo(a)!";
export const subtitleMain = "Aqui você irá imergir em autoconhecimento.";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "ALINE WETIUK";
export const nomeFornecedor = "ALINE";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/yoga_alinewettiuk/",
    profile: "@yoga_alinewettiuk"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com/channel/UCT73-fIyWYvJtl9rWtyYMRw",
  },

  {
    type: "material-community",
    name: "whatsapp",
    link: "https://wa.me/554988139327",
    number: "(49) 8813-9327"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://www.instagram.com/yoga_alinewettiuk/",
  },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];